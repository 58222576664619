<template>
  <section class="transfer">
    <h2 class="heading">Transaction History</h2>
    <el-select
      v-model="value"
      @change="getTranactions"
      clearable
      placeholder="Select Account"
      style="width:100%;"
    >
      <el-option
        v-for="account in userAccounts"
        :key="account.NUBAN"
        :label="account.NUBAN + ' - ' + account.ACCT_TYPE"
        :value="account.NUBAN"
      >
        {{ account.NUBAN }} - {{ account.ACCT_TYPE }}
      </el-option>
    </el-select>

    <section class="filter">
      <div class="filter__btn" @click="showFilter = !showFilter">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          focusable="false"
          style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 36 36"
        >
          <path
            class="clr-i-solid clr-i-solid-path-1"
            d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2zm0 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14z"
            fill="#626262"
          />
          <path
            class="clr-i-solid clr-i-solid-path-2"
            d="M8 11v1.12a.5.5 0 0 0 .15.35l7.28 7.36a.5.5 0 0 1 .15.35v6.89a.5.5 0 0 0 .28.45l3.95 1.41a.5.5 0 0 0 .72-.45v-8.39a.54.54 0 0 1 .18-.35l7.12-7.25a.5.5 0 0 0 .15-.35V11z"
            fill="#626262"
          />
          <rect x="0" y="0" width="36" height="36" fill="rgba(0, 0, 0, 0)" />
        </svg>
        <p>Filter Options</p>
      </div>
      <el-form :model="form" v-if="showFilter" class="filter__wrapper">
        <div class="filter__options">
          <el-form-item label=" Select Transaction Type"
            ><br />
            <el-select
              v-model="form.transactionType"
              size="small"
              clearable
              placeholder="By Transaction Type"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" Select Date Range">
            <el-date-picker
              clearable
              v-model="form.date"
              type="daterange"
              unlink-panels
              range-separator="-"
              start-placeholder="Start date"
              end-placeholder="End date"
              align="center"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" plain @click="getTransactionsByFilter"
            >Search</el-button
          >
        </el-form-item>
      </el-form>
    </section>

    <div
      class="trasactions__container"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <section
        v-for="transaction in transactionsHistory"
        :key="transaction.ReferenceNumber"
        class="history"
      >
        <div class="history__left">
          <h2 class="history__name">{{ transaction.Receiver.Name }}</h2>
          <h3 class="history__type">{{ transaction.ProductType }}</h3>
          <h4 class="history__date">
            {{ transaction.TransactionDate | formatDate }}
          </h4>
        </div>
        <div class="history__right">
          <h3 class="history__amount">
            {{ transaction.amount | formatCurrency }}
          </h3>
          <el-button
            plain
            size="mini"
            class="history__button"
            @click="showDetails(transaction)"
            >View</el-button
          >
        </div>
      </section>
    </div>

    <el-dialog
      v-if="modalDetails !== null"
      :visible.sync="showDetailsModal"
      :show-close="false"
      :close-on-click-modal="true"
      style="padding: 0; text-align:left"
      class="dialogue"
    >
      <div class="dialogue__heading">
        <h2>Transaction Details</h2>
        <div class="dialogue__close" @click="showDetailsModal = false">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3 10.0006L19.4873 3.81337C20.1709 3.12974 20.1709 2.02141 19.4873 1.33885L18.6624 0.514004C17.9786 -0.169839 16.8703 -0.169839 16.1877 0.514004L10.0006 6.70106L3.81337 0.512722C3.12974 -0.170907 2.02141 -0.170907 1.33885 0.512722L0.512722 1.33756C-0.170907 2.02141 -0.170907 3.12974 0.512722 3.8123L6.70106 10.0006L0.514004 16.1877C-0.169839 16.8715 -0.169839 17.9799 0.514004 18.6624L1.33885 19.4873C2.02247 20.1709 3.13081 20.1709 3.81337 19.4873L10.0006 13.3L16.1877 19.4873C16.8715 20.1709 17.9799 20.1709 18.6624 19.4873L19.4873 18.6624C20.1709 17.9786 20.1709 16.8703 19.4873 16.1877L13.3 10.0006Z"
              fill="#DB353A"
            />
          </svg>
        </div>
      </div>

      <el-divider><i class="el-icon-document"></i></el-divider>
      <div class="sub-group">
        <h3>Product Type:</h3>
        <p>{{ modalDetails.ProductType }}</p>
      </div>
      <div class="sub-group">
        <h3>Amount:</h3>
        <p>{{ modalDetails.amount | formatCurrency }}</p>
      </div>
      <div class="sub-group">
        <h3>Transaction Date:</h3>
        <p>{{ modalDetails.TransactionDate | formatDate }}</p>
      </div>
      <div class="sub-group">
        <h3>Reference Number:</h3>
        <p>{{ modalDetails.ReferenceNumber }}</p>
      </div>
      <div class="sub-group">
        <h3>Category:</h3>
        <p>{{ modalDetails.Category }}</p>
      </div>
      <div class="sub-group">
        <h3>Remarks:</h3>
        <p>{{ modalDetails.Remarks }}</p>
      </div>
      <el-divider content-position="left">Receiver</el-divider>
      <div class="sub-group">
        <h3>Name:</h3>
        <p>{{ modalDetails.Receiver.Name }}</p>
      </div>
      <div class="sub-group">
        <h3>Account Number:</h3>
        <p>{{ modalDetails.Receiver.DestinationAccount }}</p>
      </div>
      <div class="sub-group">
        <h3>Bank:</h3>
        <p>{{ modalDetails.Receiver.Institution }}</p>
      </div>
      <div class="sub-group">
        <h3>Currency:</h3>
        <p>{{ modalDetails.Receiver.Currency }}</p>
      </div>
      <el-divider content-position="left">Sender</el-divider>
      <div class="sub-group">
        <h3>Name:</h3>
        <p>{{ modalDetails.Sender.Name }}</p>
      </div>
      <div class="sub-group">
        <h3>Account Number:</h3>
        <p>{{ modalDetails.Sender.SourceAccount }}</p>
      </div>
      <div class="sub-group">
        <h3>Currency:</h3>
        <p>{{ modalDetails.Sender.Currency }}</p>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import dayjs from "dayjs";
let utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

import api from "@/api/api.js";

import { mapState } from "vuex";
export default {
  computed: mapState(["userAccounts", "user"]),
  data() {
    return {
      loading: false,
      value: "",
      startDate: "",
      endDate: "",
      transactionsHistory: [],
      modalDetails: null,
      showDetailsModal: false,
      showFilter: false,
      options: [
        {
          value: "all",
          label: "All"
        },
        {
          value: "ft",
          label: "Local"
        },
        {
          value: "fx",
          label: "Foreign"
        },
        {
          value: "airtime",
          label: "Airtime"
        },
        {
          value: "bills",
          label: "Bills"
        }
      ],
      form: {
        date: "",
        transactionType: ""
      }
    };
  },
  methods: {
    getTranactions() {
      if (!this.showFilter) {
        this.startDate = dayjs.utc().format();
        this.endDate = dayjs()
          .subtract(7, "day")
          .utc()
          .format();

        let payload = {
          SourceNuban: this.value,
          From: `${this.endDate}`,
          To: `${this.startDate}`,
          Type: "all"
        };

        this.loading = true;

        api
          .getTransactionHistory(payload)
          .then(response => {
            this.loading = false;
            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              let transactions = response.Data;

              if (transactions.length <= 0) {
                this.$message({
                  showClose: true,
                  message: `No records found.`,
                  type: "error",
                  duration: 5000
                });
              }

              let lastTwentyTransactions = transactions.slice(0, 20);
              this.transactionsHistory = lastTwentyTransactions;
            } else {
              this.$message({
                showClose: true,
                message: `${responseMessage.charAt(0).toUpperCase() +
                  responseMessage.slice(1)}`,
                type: "error",
                duration: 10000
              });
            }
          })
          .catch(error => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000
            });
          });
      }
    },
    getTransactionsByFilter() {
      if (this.showFilter) {
        let startDate = this.form.date[0];
        startDate = dayjs(String(startDate))
          .utc()
          .format();
        let endDate = this.form.date[1];
        endDate = dayjs(String(endDate))
          .utc()
          .format();

        let payload = {
          SourceNuban: this.value,
          From: startDate,
          To: endDate,
          Type: this.form.transactionType
        };

        this.loading = true;

        api
          .getTransactionHistory(payload)
          .then(response => {
            this.loading = false;
            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              let transactions = response.Data;

              if (transactions.length <= 0) {
                this.$message({
                  showClose: true,
                  message: `No records found.`,
                  type: "error",
                  duration: 5000
                });
              }

              // let lastTwentyTransactions = transactions.slice(0, 20);
              // this.transactionsHistory = lastTwentyTransactions;
              this.transactionsHistory = transactions;
            } else {
              this.$message({
                showClose: true,
                message: `${responseMessage.charAt(0).toUpperCase() +
                  responseMessage.slice(1)}`,
                type: "error",
                duration: 10000
              });
            }
          })
          .catch(error => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000
            });
          });
      }
    },
    showDetails(value) {
      this.modalDetails = value;
      this.showDetailsModal = true;
    }
  },
  mounted() {
    this.value = this.userAccounts[0].NUBAN;
    this.startDate = dayjs.utc().format();
    this.endDate = dayjs()
      .subtract(7, "day")
      .utc()
      .format();

    let payload = {
      SourceNuban: this.value,
      From: `${this.endDate}`,
      To: `${this.startDate}`,
      Type: "all"
    };

    this.loading = true;

    api
      .getTransactionHistory(payload)
      .then(response => {
        this.loading = false;
        let responseStatus = response.Status;
        let responseMessage = response.Message;

        if (responseStatus) {
          let transactions = response.Data;

          if (transactions.length <= 0) {
            this.$message({
              showClose: true,
              message: `No records found.`,
              type: "error",
              duration: 5000
            });
          }

          let lastTwentyTransactions = transactions.slice(0, 20);
          this.transactionsHistory = lastTwentyTransactions;
        } else {
          this.$message({
            showClose: true,
            message: `${responseMessage.charAt(0).toUpperCase() +
              responseMessage.slice(1)}`,
            type: "error",
            duration: 10000
          });
        }
      })
      .catch(error => {
        this.loading = false;
        this.$message({
          showClose: true,
          message: `${error}`,
          type: "error",
          duration: 10000
        });
      });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
